import App from "../views/App.tsx";
import { createBrowserRouter, Navigate, redirect } from "react-router-dom";
import ErrorPage from "../views/ErrorPage.tsx";
import { authRoutes } from "./authRoutes.tsx";
import { privateRoutes } from "./privateRoutes.tsx";
import { sxUiRoutes } from "./sxUiRoutes.tsx";
import { IS_PROD } from "../services/config.ts";
import ContactPage from "../views/contact/ContactPage.tsx";
import { userService } from "../services/user/userService.ts";
import { authService } from "../services/auth/authService.ts";
import CapsulePublicPage from "../views/organization/capsule/CapsulePublicPage.tsx";
import { getCapsuleByOrganizationSlugAndId } from "../services/capsule/capsuleApi.ts";
import { Capsule } from "../services/capsule/capsuleModel.ts";
import { loggerBuilder } from "../services/logger.ts";
import { toastsWithIntl } from "../services/toastService.tsx";
import SharedPage from "../views/organization/SharedPage.tsx";
import { getProfilesByUidRequest } from "../services/profile/profileApi.ts";
import { Profile } from "../services/profile/profileModel.ts";

const { getLoggedUser } = userService();
const { isLoggedIn } = authService();
const { toastError } = toastsWithIntl(["organization"]);

const logger = loggerBuilder("public-routes");

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/login" />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
        async loader() {
          return {
            loggedUser: isLoggedIn() ? await getLoggedUser() : null,
          };
        },
      },
      {
        path: "/employeur/:organizationSlug/:capsuleId",
        element: <CapsulePublicPage />,
        async loader({ params }) {
          const loggedUser = await getLoggedUser().catch(() =>
            Promise.resolve(null),
          );
          let capsule: Capsule | null = null;
          let video: Promise<Blob> | null = null;
          try {
            capsule = await getCapsuleByOrganizationSlugAndId(
              params.organizationSlug!,
              Number(params.capsuleId!),
            );
          } catch (e: any) {
            let message: string;
            if (e.response?.status === 404)
              message = "public:capsule.NOT_FOUND";
            else if (e.response?.status === 410)
              message = "public:capsule.DISABLED";
            else message = "public:capsule.GENERIC";
            return {
              loggedUser,
              error: {
                message,
                status: e.response?.status,
              },
            };
          }

          if (capsule?.videoLink) {
            video = (async (videoLink) => {
              const data = await fetch(videoLink, {
                method: "GET",
              });
              return new Blob(
                [new Uint8Array(await data.arrayBuffer()).reverse()],
                {
                  type: "video/mp4",
                },
              );
            })(capsule.videoLink);
          }

          return {
            loggedUser,
            capsule,
            video,
          };
        },
      },
      {
        path: "profile-share/:uid",
        element: <SharedPage />,
        async loader({ params, request }) {
          const url = new URL(request.url);
          const code = url.searchParams.get("accessCode") ?? undefined;
          const email = url.searchParams.get("email") ?? undefined;

          let profile: Profile | null = null;
          try {
            profile = await getProfilesByUidRequest(params.uid!, code, email);
          } catch (e: any) {
            switch (e.response?.status) {
              case 404:
                toastError("organization:shared-profile.ERROR_NOT_FOUND");
                return redirect("/organization/candidates");
              case 412:
                toastError("organization:shared-profile.ERROR_NOT_AN_ORG");
                return redirect("/candidate/candidates");
              case 402:
                toastError(
                  "organization:shared-profile.ERROR_PAYMENT_REQUIRED",
                  {
                    id: "payment-required",
                  },
                );
                return redirect("/organization/candidates");
              case 403:
                toastError(
                  "organization:shared-profile.ERROR_ORG_NOT_VALIDATED",
                  {
                    id: "org-not-validated",
                  },
                );
                return redirect("/organization/candidates");
              case 410:
                toastError(
                  "organization:shared-profile.ERROR_DISABLED_PROFILE",
                  {
                    id: "disabled-profile",
                  },
                );
                return redirect("/organization/candidates");
              case 200:
                break;
              default:
                logger.warn("unknown error", e);
                return redirect("/");
            }
          }

          let video = null;
          if (profile?.videoLink) {
            video = (async (videoLink) => {
              const data = await fetch(videoLink, {
                method: "GET",
              });
              return new Blob(
                [new Uint8Array(await data.arrayBuffer()).reverse()],
                {
                  type: "video/mp4",
                },
              );
            })(profile.videoLink);
          }

          return {
            profile,
            video,
          };
        },
      },
      authRoutes,
      privateRoutes,
    ],
  },
  ...(IS_PROD ? [] : [sxUiRoutes]),
]);

export default router;
